.notfound {
    padding-bottom: 100px;
    min-height: 100vh;
    color: white;
    text-align: center;
}

.notfound .container {
    margin: 0 auto;
    padding: 0 4rem;
}

.notfound .content {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.bg {
    padding-top: 60px;
    max-height: 100vh;
    background: linear-gradient(180deg, #080912 0%, rgba(8, 9, 18, 0) 73.78%), linear-gradient(180deg, rgba(8, 9, 18, 0) 16.64%, #080912 100%);
    position: relative;
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    pointer-events: none;
}

.fade {
    position: absolute;
    width: 100%;
    height: 161px;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, #040914 0%, rgba(4, 9, 20, 0) 100%);
    transform: matrix(1, 0, 0, -1, 0, 0);
}

.title {
    font-family: 'Coalition', serif;
    font-size: 3rem;
    line-height: 3.75rem;
    margin-bottom: 12px;
    color: rgba(215, 252, 81, 1);
}

.subtitle {
    font-family: 'Coalition', serif;
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 0 auto;
}

.text {
    font-family: 'Industry', serif;
    font-size: 1.5rem;
    line-height: 1.75rem;
}

.button {
    margin-top: 48px;
    padding: 0.5rem 1rem;
    background-color: #d7fc51;
    border: 0;
    font-family: 'Industry', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #1D1E29;
    cursor: pointer;
    transition: opacity .3s ease;
}

.button:hover,
.button:active {
    opacity: .7;
}

.steps {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto 1fr auto 1fr;
    margin-top: 48px;
    grid-column-gap: 12px;
    text-align: left;
}

.platforms {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    column-gap: 1rem;
}

@media only screen and (min-width: 1920px) {
    .notfound .container {
        max-width: 80%;
    }
}

@media only screen and (min-width: 1440px) and (max-width: 1920px) {
    .notfound .container {
        max-width: 90%;
    }
}

@media only screen and (max-width: 1024px) {
    .notfound .container {
        padding: 0 1rem;
    }
}

@media only screen and (max-width: 768px) {
    .notfound .container {
        padding: 0 1rem;
    }

    .notfound {
        padding-bottom: 0;
    }

    .title {
        font-size: 1.75rem;
        line-height: 2rem;
        margin-bottom: 8px;
    }

    .subtitle {
        font-size: 1.5rem;
        line-height: 2rem;
        width: 100%;
    }

    .text {
        font-size: 1.5rem;
        line-height: 2rem;
        margin-top: 20px;
    }

    .steps {
        grid-template-columns: 1fr;
        row-gap: 22px;
        justify-items: center;
    }

    .steps img {
        transform: rotate(90deg);
    }

    .platforms {
        grid-template-columns: 1fr;
        row-gap: 16px;
    }

    .platforms img {
        width: 100%;
        max-height: 80px;
        object-fit: cover;
    }

    .button {
        width: 100%;
        height: 48px;
    }
}
